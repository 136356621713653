import React, { useEffect, useState } from 'react';
import { Unsubscribe } from './pages/unsubscribe/unsubscribe.component';
import { BrowserRouter, Route } from 'react-router-dom';

const USER_ID_PARAM = 'utm_tag';

function App() {
  const [userId, setUserId] = useState('');

  useEffect(() => setUserId(popUserIdFromUrl()), []);

  return (
    <BrowserRouter>
      <Route path={['/', '/cosm']}>
        <Unsubscribe userId={userId} />
      </Route>
    </BrowserRouter>
  );
}

function popUserIdFromUrl() {
  const url = window.location;
  const urlParams = new URLSearchParams(url.search);
  const userId = urlParams.get(USER_ID_PARAM) || '';
  urlParams.delete(USER_ID_PARAM);
  window.history.replaceState(
    null,
    '',
    url.protocol + '//' + url.host + url.pathname + urlParams.toString()
  );
  return userId;
}

export default App;
