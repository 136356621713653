import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface ErrorDialogProps {
  errorMessage: string;
  handleHide: Function;
}

export function ErrorDialog(props: ErrorDialogProps) {
  return (
    <Modal show={true} onHide={props.handleHide}>
      <Modal.Header closeButton />
      <Modal.Body>{props.errorMessage}</Modal.Body>
    </Modal>
  );
}
