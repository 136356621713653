import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorDialog } from '../components/error-dialog/error-dialog.component';

export default function showError(message: string) {
  const errorDialogProps = {
    errorMessage: message,
    handleHide: () =>
      ReactDOM.unmountComponentAtNode(
        document.getElementById('errors-popup') as HTMLElement
      ),
  };
  ReactDOM.render(
    React.createElement(ErrorDialog, errorDialogProps),
    document.getElementById('errors-popup')
  );
}
