const API_BASE_URL =
  'https://w8iya5zzt8.execute-api.eu-central-1.amazonaws.com/dev';
const API_BASE_HEADERS = {
  'Content-Type': 'application/json',
  'X-API-Key': '5KislEPekPaeuumZfELCr6LuFDLkEkrilAuEfo81',
};

const searchUser = (phone: string) => {
  const apiUrl = `${API_BASE_URL}/dentists?phone=${phone}`;
  const options = { method: 'GET', headers: API_BASE_HEADERS };

  return fetch(apiUrl, options)
    .then((response: Response) => {
      if (!response.ok) {
        console.error(
          `search user error: ${response.status}; ${response.statusText}`
        );

        throw new Error(`Fetch error. ${response.statusText}`);
      }

      return response.json();
    })
    .then((result) => {
      if (result.length === 0) {
        throw new Error(
          `Користувача с таким телефоном не знайдено. Перевірте номер телефону.`
        );
      }

      return result[0].id;
    });
};

const sendUnsubRequest = (userId: string, reason: string) => {
  const apiUrl = `${API_BASE_URL}/dentists`;
  const options = {
    method: 'PUT',
    headers: API_BASE_HEADERS,
    body: JSON.stringify({ id: userId, subscribe: false }),
  };

  return fetch(apiUrl, options).then((response: Response) => {
    if (!response.ok) {
      console.error(
        `dentists unsubRequest error: ${response.status}; ${response.statusText}`
      );

      throw new Error(`Fetch error. ${response.statusText}`);
    } else if (!!reason) {
      return sendUnsubReason(userId, reason);
    } else {
      return response;
    }
  });
};

const sendUnsubReason = (userId: string, reason: string) => {
  const apiUrl = `${API_BASE_URL}/dentists-surveys`;
  const data = {
    survey: [
      {
        dentist_id: userId,
        survey_description: 'unsubscribe',
        question: 'comment',
        answer: reason,
      },
    ],
  };
  const options = {
    method: 'POST',
    headers: API_BASE_HEADERS,
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, options).then((response: Response) => {
    if (!response.ok) {
      console.error(
        `dentists-survey error: ${response.status}; ${response.statusText}`
      );
    }

    return response;
  });
};

export { searchUser, sendUnsubReason, sendUnsubRequest };
