import React, { ChangeEvent, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './unsubscribe.module.scss';
import dentistLogo from '../../img/dentist-logo-big.png';
import dentistLogoIcon from '../../img/dentist-logo-icon.png';
import cosmLogo from '../../img/cosm-logo-big.png';
import cosmLogoIcon from '../../img/cosm-logo-icon.png';
import Spinner from 'react-bootstrap/Spinner';
import InputMask from 'react-input-mask';
import {
  searchUser,
  sendUnsubRequest,
} from '../../services/unsubscribe.service';
import showError from '../../utils/error-dialog.service';
import Favicon from 'react-favicon';

interface UnsubscribeProps {
  userId: string;
}

const Unsubscribe = ({ userId }: UnsubscribeProps) => {
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [isUnsub, setIsUnsub] = useState(false);
  const [reason, setReason] = useState('');
  const [phone, setPhone] = useState('');

  const checkPath = (path: string, exact?: boolean) =>
    !!matchPath(path, {
      exact: exact || true,
      path: pathname,
    });

  const { isCosmUnsub, isDentistUnsub } = useMemo(
    () => ({
      isDentistUnsub: checkPath('/', false),
      isCosmUnsub: checkPath('/cosm'),
    }),
    [pathname, checkPath]
  );

  const reasonChanged = (event: ChangeEvent) => {
    setReason((event.target as any).value);
  };

  const phoneChanged = (event: ChangeEvent) => {
    setPhone((event.target as any).value);
  };

  const handleUnsub = async () => {
    if (!userId && !phone) {
      showError('Будь ласка, вкажіть номер телефону');
      return;
    }

    setLoading(true);

    if (!userId) {
      // eslint-disable-next-line no-useless-escape
      const unmaskedPhone = phone.replace(/[\+\)\(\-]/g, '');

      try {
        userId = await searchUser(unmaskedPhone);
      } catch (err) {
        showError(err.message);
        setLoading(false);

        return;
      }
    }
    sendUnsubRequest(userId, reason)
      .then(
        () => setIsUnsub(true),
        (err: Error) => showError(err.message)
      )
      .finally(() => setLoading(false));
  };

  const faviconLogoUrl = isCosmUnsub
    ? cosmLogoIcon
    : isDentistUnsub
    ? dentistLogoIcon
    : '';

  const bigLogoUrl = isCosmUnsub ? cosmLogo : isDentistUnsub ? dentistLogo : '';

  return (
    <div className='container-fluid p-3 py-md-4 p-xl-5'>
      <Favicon url={faviconLogoUrl} />
      {isUnsub &&
        respCenterRowWide(
          <>
            <br />
            <br />
            <br />
            <h3>Ваші контактні дані видалені з розсилки від IQOSscience</h3>
            <br />
            <br />
          </>
        )}
      {respCenterRowNarrow(
        <img src={bigLogoUrl} alt='Logo' className={styles['hrp-logo']} />
      )}
      {!isUnsub &&
        respCenterRowWide(
          <>
            <br />
            <br />
            <h3>Нам буде Вас не вистачати☹</h3>
            <br />
            <br />
            <br />
            {!userId && (
              <InputMask
                mask='+38(999)999-99-99'
                placeholder='+38(___)___-__-__'
                className={classNames(styles['unsub-input'], {
                  [styles['unsub-input-dentist']]: isDentistUnsub,
                  [styles['unsub-input-cosm']]: isCosmUnsub,
                })}
                value={phone}
                onChange={phoneChanged}
              />
            )}
            <h3>
              Будемо дуже вдячні за Ваш відгук про причину відписки від розсилки
            </h3>
            <textarea
              rows={6}
              className={classNames(styles['unsub-cause'], {
                [styles['unsub-input-dentist']]: isDentistUnsub,
                [styles['unsub-input-cosm']]: isCosmUnsub,
              })}
              value={reason}
              onChange={reasonChanged}
            />
            {userId && (
              <>
                <br />
                <br />
              </>
            )}
            {loading ? (
              <Spinner animation='border' className={styles['unsub-spinner']} />
            ) : (
              <button
                type='button'
                className={`btn btn-secondary ${styles['unsub-btn']}`}
                onClick={handleUnsub}
              >
                ВІДПИСАТИСЯ ВІД
                <br />
                IQOSSCIENCE
              </button>
            )}
          </>
        )}
    </div>
  );
};

const respCenterRowNarrow = (children: React.ReactNode) => (
  <div className='row justify-content-center'>
    <div className='col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4'>{children}</div>
  </div>
);

const respCenterRowWide = (children: React.ReactNode) => (
  <div className='row justify-content-center'>
    <div className='col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6'>
      {children}
    </div>
  </div>
);

export { Unsubscribe };
